import { styled } from "@mui/system";
import { Paper, Typography, Box, Button } from "@mui/material";

export const StyledPaperMain = styled(Paper)(({ theme }) => ({
  padding: "1vw",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  background: "linear-gradient(180deg, #ffffff 0%, #e6e6e6 100%)",
  borderRadius: "5px",
  boxShadow: "none",
  alignItems: "center",
  textAlign: "center",
  cursor: "pointer",
}));

export const StyledPaperHover = styled(Paper)(({ theme }) => ({
  padding: "1vw",
  textAlign: "left",
  width: "fit-content",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  background: "linear-gradient(0deg, #ffffff 0%, #dfdfdf 100%)",
  borderRadius: "5px",
  boxShadow: "none",
  cursor: "pointer",
  marginTop: "2px",
  "&:hover": {
    backgroundColor: "#cbcbcb",
    background: "linear-gradient(0deg, #cbcbcb 0%, #dfdfdf 100%)",
  },
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: "normal",
  color: "#124734",
  [theme.breakpoints.up("md")]: {
    fontSize: "14px",
  },
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  marginTop: "0px",
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  marginBottom: "1vw",
  marginTop: "1.2vw",
  fontFamily: "Inter, sans-serif",
  letterSpacing: "3px",
  fontWeight: "500",
  color: "#369902",
}));

export const StyledImage = styled("img")(({ theme }) => ({
  width: "6vw",
  [theme.breakpoints.up("md")]: {
    width: "3vw",
  },
}));

// Styled Paragraph component with custom styles
export const StyledParagraph = styled(Typography)(({ theme }) => ({
  color: "#7D7D7D",
  fontFamily: "'Montserrat', sans-serif",
  fontSize: "12px",
  margin: "10px 10px",
  fontWeight: 500,
  display: "flex",
  justifyContent: "left",
  whiteSpace: "pre", // Keep text in one line initially
  overflow: "hidden", // Hide overflowing text
  textOverflow: "ellipsis", // Add ellipsis when text overflows
  transition: "max-height 0.3s ease-out", // Smooth transition for expanding/collapsing
  maxHeight: "30px", // Limit the height for the one-line state
  maxWidth: "70vw",
}));

export const FullDisclaimer = styled(Typography)(({ theme }) => ({
  color: "#7D7D7D",
  fontFamily: "'Montserrat', sans-serif",
  fontSize: "12px",
  margin: "10px 20px",
  fontWeight: 500,
  maxWidth: "70vw",
}));

export const ReadMoreButton = styled(Button)(({ theme }) => ({
  background: "none",
  cursor: "pointer",
  fontWeight: "500",
  padding: "0", // To keep the button compact
  minWidth: "auto", // Remove extra width
  textTransform: "lowercase",
  textDecoration: "underline",
  color: "#124734",
  fontSize: "12px",
  whiteSpace: "nowrap",
}));

// Styled Paragraph component with custom styles
export const StyledParagraph1 = styled(Typography)(({ theme }) => ({
  color: "#7D7D7D",
  fontFamily: "'Montserrat', sans-serif",
  fontSize: "12px",
  margin: "10px 20px",
  fontWeight: 500,
  display: "flex",
  justifyContent: "center",
  whiteSpace: "nowrap", // Keep text in one line initially
  overflow: "hidden", // Hide overflowing text
  textOverflow: "ellipsis", // Add ellipsis when text overflows
  transition: "max-height 0.3s ease-out", // Smooth transition for expanding/collapsing
  maxHeight: "36px", // Limit the height for the one-line state
}));
