import { appInsights } from "../App";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const apiConfig = {
  endpoints: {
    register: `${API_BASE_URL}/gw/authorize/auth-regular/register`,
    login: `${API_BASE_URL}/gw/authorize/auth-regular/authenticate`,
    requestVerifyToken: `${API_BASE_URL}/gw/authorize/auth-regular/initiate-verification`,
    verifyToken: `${API_BASE_URL}/gw/authorize/auth-regular/verify-token`,
    requestPasswordVerification: `${API_BASE_URL}/gw/authorize/auth-regular/initiate-verification`,
    resetPassword: `${API_BASE_URL}/gw/authorize/auth-regular/change-password`,
    getUserDetails: (userId) =>
      `${API_BASE_URL}/gw/authorize/user/get-user/${userId}`,
    hotHandoff: `${API_BASE_URL}/gw/hot-handoff/api/v1/handoff/create-handoff`,
    getUTM: `${API_BASE_URL}/gw/utm/save-utm`,
    getResponse: `${API_BASE_URL}/gw/backend/ai_services/get_zack_answer`,
    getHistory: `${API_BASE_URL}/gw/backend/chatdb_services/get_user_chat_questions`,
    getSuggest: `${API_BASE_URL}/gw/backend/ai_services/get_user_seed_questions`,
    submitFeedback: `${API_BASE_URL}/gw/backend/chatdb_services/update_chat_turn_with_feedback`,
    submitUserProfile: `${API_BASE_URL}/gw/backend/chatdb_services/add_user_investment_profile`,
    getUserProfile: `${API_BASE_URL}/gw/backend/chatdb_services/get_user_investment_profile`,
    trackHotHandoff: `${API_BASE_URL}/gw/backend/chatdb_services/track_advisory_tag_interaction`,
    googleRedirect: `${API_BASE_URL}/gw/authorize/auth-social/authenticate?oauth_provider=google`,
    metaRedirect: `${API_BASE_URL}/gw/authorize/auth-social/authenticate?oauth_provider=meta`,
  },
};

export const fetchAuthData = async (endpoint, method, body, params = {}) => {
  let urlString;

  // Check if endpoint is a key in apiConfig.endpoints
  if (apiConfig.endpoints[endpoint]) {
    urlString = apiConfig.endpoints[endpoint]; // Get URL from config
  } else {
    urlString = endpoint; // Use as-is if already a full URL
  }

  const url = new URL(urlString);

  Object.keys(params).forEach((key) =>
    url.searchParams.append(key, params[key])
  );
  const options = {
    method,
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  if (body) {
    options.body = JSON.stringify(body); // Convert body to JSON string
  }

  try {
    const response = await fetch(url, options);

    const contentType = response.headers.get("Content-Type");
    let responseData;

    if (contentType && contentType.includes("application/json")) {
      responseData = await response.json();
    } else {
      responseData = { message: await response.text() };
    }
    responseData.statusCode = response.status;
    if (!response.ok) {
      console.error("API Error:", responseData);
    }

    return responseData;
  } catch (error) {
    appInsights.trackException({
      exception: error,
      properties: {
        message: error.message,
        stack: error.stack,
      },
    });
    console.error("Fetch error:", error);
  }
};

// Fetch data function
export const fetchData = async (endpoint, method, body) => {
  const options = {
    method,
    headers: {
      "Content-Type": "application/json",
      // Add other headers if necessary (e.g., Authorization)
    },
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  try {
    const response = await fetch(apiConfig.endpoints[endpoint], options);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error("Fetch error:", error);
    // throw error; // Rethrow the error for further handling
  }
};

export const fetchStream = async (
  endpoint,
  method,
  body,
  setMessages,
  setStatus,
  abortController,
  onResponsewithTicker
) => {
  const options = {
    method,
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      // Add other headers if necessary (e.g., Authorization)
    },
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  // If an AbortController is passed, add its signal to the fetch options
  if (abortController) {
    options.signal = abortController.signal;
  }
  try {
    // Send the POST request
    const response = await fetch(apiConfig.endpoints[endpoint], options);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let buffer = "";

    let done = false;
    let fullresponse = "";
    while (!done) {
      if (abortController.signal.aborted) {
        // console.log("Request aborted during streaming");
        break; // Exit the loop if the request is aborted
      }
      const { value, done: streamDone } = await reader.read();
      done = streamDone;
      if (value) {
        // Decode the current chunk
        buffer += decoder.decode(value, { stream: true });

        // Split the buffer by known JSON boundaries (you could use newlines or other markers if provided)
        let boundaryIndex;
        // console.log(buffer);
        while ((boundaryIndex = buffer.indexOf("\n")) !== -1) {
          const jsonChunk = buffer.slice(0, boundaryIndex + 1); // Take the first complete JSON object
          // console.log(jsonChunk);
          buffer = buffer.slice(boundaryIndex + 1); // Keep the rest for the next chunk
          // console.log(buffer);
          try {
            const parsedData = JSON.parse(jsonChunk);
            // console.log(parsedData.content);
            if (
              parsedData.message_type === "model_response_chunk" &&
              parsedData.content !== "```" &&
              parsedData.content !== "html"
            ) {
              let newContent = parsedData.content;
              // console.log(newContent);
              // Check if the new chunk is empty or contains just whitespace
              if (newContent.match(/^\s{2,}/) || newContent.match(/\s{2,}$/)) {
                // Trim only if there is more than 1 whitespace at the start or end
                newContent = newContent.trim();
              }
              if (newContent.length === 0) {
              } else {
                fullresponse += newContent;
                await new Promise((resolve) => setTimeout(resolve, 20));
                // eslint-disable-next-line no-loop-func
                setMessages((prevMessages) => [
                  ...prevMessages.slice(0, -1),
                  {
                    text: fullresponse,
                    isLoading: false,
                    isStreaming: true,
                  },
                ]);
              }
            } else if (parsedData.message_type === "status") {
              setStatus(parsedData.content);
              await new Promise((resolve) => setTimeout(resolve, 200));
            } else if (parsedData.message_type === "session_data") {
              // console.log(fullresponse);
              if (parsedData.content && parsedData.content.prompt_id) {
                // eslint-disable-next-line no-loop-func
                setMessages((prevMessages) => [
                  ...prevMessages.slice(0, -1),
                  {
                    text: fullresponse,
                    isLoading: false,
                    isLoadingError: false,
                    isStreaming: false,
                    isFeedback: true,
                    promptId: parsedData.content.prompt_id,
                  },
                ]);
              }
            } else if (parsedData.message_type === "ticker_data") {
              if (parsedData.content) {
                onResponsewithTicker(parsedData.content);
              }
            } else if (parsedData.message_type === "user_profile_capture") {
              if (parsedData.content) {
                // eslint-disable-next-line no-loop-func
                setMessages((prevMessages) => [
                  ...prevMessages.slice(0, -1),
                  {
                    text: "UserProfileCapture",
                    isLoading: false,
                    isLoadingError: false,
                    isStreaming: false,
                    isFeedback: false,
                    isUserProfileCapture: true,
                  },
                ]);
              }
              return;
            } else if (parsedData.message_type === "error") {
              setMessages((prevMessages) => [
                ...prevMessages.slice(0, -1),
                {
                  text: parsedData.content
                    ? parsedData.content
                    : "An error has occurred. Please try again later.",
                  isLoading: false,
                  isLoadingError: true,
                  isStreaming: false,
                },
              ]);
            }
          } catch (err) {
            console.error("Error parsing JSON:", err, jsonChunk);
            appInsights.trackException({
              exception: err,
              properties: {
                message: err.message,
                stack: err.stack,
              },
            });
          }
        }
      }
    }

    // Once done, you might have the final valid JSON in the buffer
    if (buffer.length > 0) {
      try {
        // console.log("parsing final chunk");
        const parsedData = JSON.parse(buffer); // Parse the final chunk
        if (
          parsedData.message_type === "model_response_chunk" &&
          parsedData.content !== "```" &&
          parsedData.content !== "html"
        ) {
          fullresponse += parsedData.content;
          setMessages((prevMessages) => [
            ...prevMessages.slice(0, -1),
            { text: fullresponse, isLoading: false, isStreaming: false },
          ]);
        } else if (parsedData.message_type === "status") {
          setMessages((prevMessages) => [
            ...prevMessages.slice(0, -1),
            {
              text: "Sorry server is busy at the moment. Please try again later.",
              isLoading: false,
              isLoadingError: true,
              isStreaming: false,
            },
          ]);
        } else if (parsedData.message_type === "error") {
          setMessages((prevMessages) => [
            ...prevMessages.slice(0, -1),
            {
              text: parsedData.content
                ? parsedData.content
                : "An error has occurred. Please try again later.",
              isLoading: false,
              isLoadingError: true,
              isStreaming: false,
            },
          ]);
        } else if (parsedData.message_type === "session_data") {
          // console.log("here is the prompt id");
          if (parsedData.content && parsedData.content.prompt_id) {
            setMessages((prevMessages) => [
              ...prevMessages.slice(0, -1),
              {
                text: fullresponse,
                isLoading: false,
                isLoadingError: false,
                isStreaming: false,
                isFeedback: true,
                promptId: parsedData.content.prompt_id,
              },
            ]);
          }
          // console.log(parsedData.content);
        }
      } catch (err) {
        console.error("Error parsing final JSON chunk:", err, buffer);
      }
    }

    reader.releaseLock();
  } catch (error) {
    appInsights.trackException({
      exception: error,
      properties: {
        message: error.message,
        stack: error.stack,
      },
    });
    console.error("Fetch error:", error);
    if (abortController && abortController.signal.aborted) {
      // console.log("Request was aborted");
    } else {
      setMessages((prevMessages) => [
        ...prevMessages.slice(0, -1),
        {
          text: "Service is down at the moment. Please try again later.",
          isLoading: false,
          isLoadingError: true,
        },
      ]);
    }
  } finally {
    setStatus("Loading...");
  }
};

export const fectProfileData = async (endpoint, method, body, params = {}) => {
  let urlString;

  // Check if endpoint is a key in apiConfig.endpoints
  if (apiConfig.endpoints[endpoint]) {
    urlString = apiConfig.endpoints[endpoint]; // Get URL from config
  } else {
    urlString = endpoint; // Use as-is if already a full URL
  }

  const url = new URL(urlString);

  Object.keys(params).forEach((key) =>
    url.searchParams.append(key, params[key])
  );
  const options = {
    method,
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  if (body) {
    options.body = JSON.stringify(body); // Convert body to JSON string
  }
  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      let errorData;
      const contentType = response.headers.get("Content-Type");
      if (contentType && contentType.includes("application/json")) {
        errorData = await response.json();
      } else {
        errorData = await response.text();
      }
      console.error("Error data:", errorData);
      appInsights.trackException({
        exception: errorData,
        properties: {
          message: errorData.message,
          stack: errorData.stack,
        },
      });
      // throw new Error(`HTTP error! status: ${response.status}`);
      return errorData;
    }
    return await response.json();
  } catch (error) {
    appInsights.trackException({
      exception: error,
      properties: {
        message: error.message,
        stack: error.stack,
      },
    });
    console.error("Fetch error:", error);
    // throw error; // Rethrow the error for further handling
  }
};

export const generateUUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export default apiConfig;
