import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import LoginDialog from "../../components/templates/LoginDialog/LoginDialog";
import RegisterDialog from "../../components/templates/LoginDialog/RegisterDialog";
import VerificationDialog from "../../components/templates/LoginDialog/VerificationDialog";
import ResestPasswordDialog from "../../components/templates/LoginDialog/ResetPasswordDialog";
import {
  fetchAuthData,
  apiConfig,
  generateUUID,
} from "../../services/apiConfig";
import LandingHeader from "../../components/templates/LandingHeader/LandingHeader";

const Login = () => {
  const { login } = useContext(AuthContext);
  const { logout } = useContext(AuthContext);
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [openRegisterDialog, setOpenRegisterDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [transit, setTransit] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const [isPasswordMatch, setIsPasswordMatch] = useState(null);
  const [APIError, setAPIError] = useState("");
  const [verificationError, setVerificationError] = useState("");
  const [isEmailValid, setIsEmailValid] = useState("");
  const [openVerificationDialog, setOpenVerificationDialog] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState(false);
  const [resetPassword, setResetPassword] = useState("");
  const [retypeResetPassword, setRetypeResetPassword] = useState("");
  const [passwordResetDone, setPasswordResetDone] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const tokenParam = searchParams.get("token");
  const resetpassParam = searchParams.get("resetpass");
  const userIDParam = searchParams.get("userId");
  const loginStatus = searchParams.get("userLogin");
  const isNewUser = searchParams.get("isNewUser");

  const utmSource = searchParams.get("utm_source");
  const utmMedium = searchParams.get("utm_medium");
  const utmCampaign = searchParams.get("utm_campaign");
  const utmTerm = searchParams.get("utm_term");
  const utmContent = searchParams.get("utm_content");
  const utmTimestamp = new Date().toISOString();

  useEffect(() => {
    // Check if UTM parameters are available and store them in sessionStorage
    if (utmSource || utmMedium || utmCampaign || utmTerm || utmContent) {
      const utmParameters = {
        utm_source: utmSource || "",
        utm_medium: utmMedium || "",
        utm_campaign: utmCampaign || "",
        utm_term: utmTerm || "",
        utm_content: utmContent || "",
        utm_timestamp: utmTimestamp,
      };

      // Store the UTM parameters object in sessionStorage
      sessionStorage.setItem("utm_parameters", JSON.stringify(utmParameters));
    }
    const storedUtmParams = JSON.parse(
      sessionStorage.getItem("utm_parameters")
    );
    const verifyToken = async () => {
      if (tokenParam && resetpassParam) {
        logout();
        setOpenResetPasswordDialog(true);
      } else if (tokenParam && !resetpassParam) {
        logout();
        try {
          // Simulate network delay to test token verification
          const response = await fetchAuthData("verifyToken", "GET", null, {
            flow: "email_verification",
            token: tokenParam,
          });
          console.log(response);
          if (response && response.statusCode !== 200) {
            // Token verification failed, handle accordingly (e.g., show error message)
            setVerificationError("Token verification failed");
            handleLoginClick();
          } else {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "userEmailVerified",
              email: response.user_data.email,
            });
            try {
              const UTMresponse = await fetchAuthData("getUTM", "POST", {
                email: response.user_data.email,
                campaigns: [
                  {
                    campaign_id: generateUUID(), // Replace with actual campaign ID if you have one
                    utm_parameters: storedUtmParams ? storedUtmParams : [],
                  },
                ],
              });
              console.log(UTMresponse);
            } catch (error) {
              console.error("Error during login:", error);
            }
            response.user_data && response.user_data.email
              ? login(response.user_data.email)
              : login("unknown email");
            navigate("/", { replace: true });
          }
        } catch (error) {
          // console.error("Error during login:", error);
        } finally {
          // setLoading(false); // Uncomment if you are managing loading state
        }
      }
    };

    const fetchUserData = async () => {
      if (userIDParam) {
        try {
          const userResponse = await fetchAuthData(
            apiConfig.endpoints.getUserDetails(userIDParam), // Inject userId into the path
            "GET",
            null
          );
          if (userResponse && userResponse.statusCode === 200) {
            // Retrieve UTM parameters from sessionStorage
            if (isNewUser === "True") {
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: "SocialLoginVerified",
                email: userResponse.email,
              });
              try {
                const response = await fetchAuthData("getUTM", "POST", {
                  email: userResponse.email,
                  campaigns: [
                    {
                      campaign_id: generateUUID(), // Replace with actual campaign ID if you have one
                      utm_parameters: storedUtmParams ? storedUtmParams : [],
                    },
                  ],
                });
              } catch (error) {
                console.error("Error during UTM record:", error);
              }
            }
            login(userResponse.email);
            navigate("/", { replace: true });
            // Handle successful user data retrieval
          } else {
            console.error("Failed to fetch user data");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      } else if (loginStatus === "Failed") {
        setVerificationError("User login failed. Please try again.");
        handleLoginClick();
      }
    };
    verifyToken();
    fetchUserData();
  }, [location]);

  const handleLoginClick = () => setOpenLoginDialog(true);
  const handleRegisterClick = () => setOpenRegisterDialog(true);
  const handleCloseDialog = () => {
    setOpenLoginDialog(false);
    setOpenRegisterDialog(false);
    setOpenVerificationDialog(false);
    setOpenResetPasswordDialog(false);
    setVerificationError("");
    setTransit(false);
    setNewPassword("");
    setRetypePassword("");
    setAPIError(false);
    setIsPasswordMatch(null);
    setIsEmailValid(true);
    setLoading(false);
    setAPIError(false);
    setPassword("");
    setUsername("");
    setIsResettingPassword(false);
    setResetPassword("");
    setRetypeResetPassword("");
    setPasswordResetDone(false);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // console.log(re.test(email));
    return re.test(email);
  };

  const handleVerifyDialog = async () => {
    if (validateEmail(username)) {
      // console.log("verification pending");
      setOpenLoginDialog(false);
      setOpenRegisterDialog(false);
      setOpenVerificationDialog(true);
      setTransit(false);
      setPassword("");
      setVerificationError("");
      // setVerificationFailed(false);
    } else {
      // console.log("Invalid email address");
      setVerificationError("");
      setTransit(true);
      await new Promise((resolve) => setTimeout(resolve, 200));
      setIsEmailValid(false);
      setTransit(false);
    }
  };

  const handleSendVerificationDialogEmail = async () => {
    setAPIError(false);
    handleVerifyDialog();
    sendVerificationEmail();
  };

  const handlePasswordSendVerificationDialog = async () => {
    setAPIError(false);
    setIsResettingPassword(true);
    handleVerifyDialog();
    sendPasswordVerificationEmail();
  };

  const handleLogin = async () => {
    if (validateEmail(username)) {
      setLoading(true);
      try {
        // console.log("trying to log in");
        const response = await fetchAuthData("login", "POST", {
          email: username,
          password: password,
        });
        if (response) {
          if (response && response.code !== 200) {
            setTransit(true);
            await new Promise((resolve) => setTimeout(resolve, 200));
            response.message_details
              ? setAPIError(response.message_details)
              : setAPIError("Invalid username or password.");
          } else {
            setOpenLoginDialog(false);
            login(username);
            navigate("/", { replace: true });
          }
        }
      } catch (error) {
        console.error("Error during login:", error);
        setTransit(true);
        await new Promise((resolve) => setTimeout(resolve, 200));
        setAPIError("An error has occurred. Please try again.");
      } finally {
        setLoading(false);
        setTransit(false);
      }
    } else {
      setTransit(true);
      await new Promise((resolve) => setTimeout(resolve, 200));
      // console.log("Invalid email address");
      setIsEmailValid(false);
      setTransit(false);
    }
  };

  const handleRegister = async () => {
    if (validateEmail(username)) {
      if (
        newPassword === retypePassword &&
        newPassword.length !== 0 &&
        retypePassword.length !== 0
      ) {
        setLoading(true);
        try {
          // console.log("trying to register");
          const response = await fetchAuthData("register", "PUT", {
            email: username,
            password: newPassword,
          });
          if (response) {
            if (response && response.code !== 200) {
              setTransit(true);
              await new Promise((resolve) => setTimeout(resolve, 200));
              response.message_details
                ? setAPIError(response.message_details)
                : setAPIError("Please enter a valid input.");
            } else {
              setTransit(true);
              await new Promise((resolve) => setTimeout(resolve, 200));
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: "userRegistered",
                email: username,
              });
              handleVerifyDialog();
            }
          }
        } catch (error) {
          console.error("Error during register:", error);
          setTransit(true);
          await new Promise((resolve) => setTimeout(resolve, 200));
          setAPIError("An error has occurred. Please try again.");
        } finally {
          setLoading(false);
          setTransit(false);
        }
      } else {
        // console.log("Password dont match.");
        setTransit(true);
        await new Promise((resolve) => setTimeout(resolve, 200));
        setIsPasswordMatch(false);
        setTransit(false);
      }
    } else {
      // console.log("Invalid email address");
      setTransit(true);
      await new Promise((resolve) => setTimeout(resolve, 200));
      setIsEmailValid(false);
      setTransit(false);
    }
  };

  const sendVerificationEmail = async () => {
    if (validateEmail(username)) {
      setLoading(true);
      try {
        // console.log("trying to send verification email");
        const response = await fetchAuthData(
          "requestVerifyToken",
          "GET",
          null,
          {
            email: username,
            flow: "email_verification",
          }
        );
        if (response) {
          if (response && response.statusCode !== 200) {
            setTransit(true);
            await new Promise((resolve) => setTimeout(resolve, 200));
            response.message_details
              ? setAPIError(response.message_details)
              : setAPIError(
                  "There was an error sending the verification email."
                );
          } else {
            setTransit(true);
            await new Promise((resolve) => setTimeout(resolve, 200));
            // setAPIError("Verification email sent");
          }
        }
      } catch (error) {
        console.error("Error during sending out verification:", error);
        setTransit(true);
        await new Promise((resolve) => setTimeout(resolve, 200));
        setAPIError(
          "Error during sernding out verification. Please try again."
        );
      } finally {
        setLoading(false);
        setTransit(false);
      }
    } else {
      // console.log("Invalid email address");
      setTransit(true);
      await new Promise((resolve) => setTimeout(resolve, 200));
      setIsEmailValid(false);
      setTransit(false);
    }
  };

  const sendPasswordVerificationEmail = async () => {
    if (validateEmail(username)) {
      setLoading(true);
      try {
        // console.log("trying to send verification email");
        const response = await fetchAuthData(
          "requestPasswordVerification",
          "GET",
          null,
          {
            email: username,
            flow: "reset_password",
          }
        );

        if (response && response.statusCode !== 200) {
          setTransit(true);
          await new Promise((resolve) => setTimeout(resolve, 200));
          response.message_details
            ? setAPIError(response.message_details)
            : setAPIError("There was an error sending the verification email.");
        } else {
          setTransit(true);
          await new Promise((resolve) => setTimeout(resolve, 200));
          // setAPIError("Verification email sent");
        }
      } catch (error) {
        console.error("Error during sending out verification:", error);
        setTransit(true);
        await new Promise((resolve) => setTimeout(resolve, 200));
        setAPIError(
          "Error during sernding out verification. Please try again."
        );
      } finally {
        setLoading(false);
        setTransit(false);
      }
    } else {
      // console.log("Invalid email address");
      setTransit(true);
      await new Promise((resolve) => setTimeout(resolve, 200));
      setIsEmailValid(false);
      setTransit(false);
    }
  };

  const handleResetPassword = async () => {
    if (
      resetPassword === retypeResetPassword &&
      resetPassword.length !== 0 &&
      retypeResetPassword.length !== 0
    ) {
      if (resetPassword.length > 8) {
        setLoading(true);
        try {
          // console.log("resetting password");
          const response = await fetchAuthData("resetPassword", "PATCH", {
            token: tokenParam,
            password: resetPassword,
          });
          if (response) {
            if (response && response.statusCode !== 200) {
              setTransit(true);
              await new Promise((resolve) => setTimeout(resolve, 200));
              response.detail === "RESET_PASSWORD_BAD_TOKEN"
                ? setAPIError(
                    "Its a expired token. Please re-try with a new token."
                  )
                : setAPIError("An error has occurred. Please try again.");
            } else {
              setTransit(true);
              await new Promise((resolve) => setTimeout(resolve, 200));
              setPasswordResetDone(true);
              handleLoginClick();
            }
          } else {
            setTransit(true);
            await new Promise((resolve) => setTimeout(resolve, 200));
            setPasswordResetDone(true);
            handleLoginClick();
          }
        } catch (error) {
          console.error("Error during re-set password:", error);
          setTransit(true);
          await new Promise((resolve) => setTimeout(resolve, 200));
          setAPIError("An error has occurred. Please try again.");
        } finally {
          setLoading(false);
          setTransit(false);
        }
      } else {
        setTransit(true);
        await new Promise((resolve) => setTimeout(resolve, 200));
        setAPIError("Password shorter than 8 characters.");
        setTransit(false);
      }
    } else {
      // console.log("Password dont match.");
      setTransit(true);
      await new Promise((resolve) => setTimeout(resolve, 200));
      setIsPasswordMatch(false);
      setTransit(false);
    }
  };

  return (
    <Box style={{ overflow: "hidden" }}>
      <LandingHeader
        props
        handleRegisterClick={handleRegisterClick}
        handleLoginClick={handleLoginClick}
      />
      {/* Login Dialog */}
      <LoginDialog
        open={openLoginDialog}
        onClose={handleCloseDialog}
        onLogin={handleLogin}
        username={username}
        setUsername={setUsername}
        password={password}
        setPassword={setPassword}
        loading={loading}
        setLoading={setLoading}
        APIError={APIError}
        setAPIError={setAPIError}
        isEmailValid={isEmailValid}
        setIsEmailValid={setIsEmailValid}
        setOpenRegisterDialog={setOpenRegisterDialog}
        transit={transit}
        setTransit={setTransit}
        sendVerificationEmail={handleSendVerificationDialogEmail}
        sendPasswordVerificationEmail={handlePasswordSendVerificationDialog}
        verificationError={verificationError}
        setVerificationError={setVerificationError}
        passwordResetDone={passwordResetDone}
      />
      {/* Register Dialog */}
      <RegisterDialog
        open={openRegisterDialog}
        onClose={handleCloseDialog}
        onRegister={handleRegister}
        username={username}
        setUsername={setUsername}
        newPassword={newPassword}
        setNewPassword={setNewPassword}
        retypePassword={retypePassword}
        setRetypePassword={setRetypePassword}
        isPasswordMatch={isPasswordMatch}
        setIsPasswordMatch={setIsPasswordMatch}
        loading={loading}
        setLoading={setLoading}
        APIError={APIError}
        setAPIError={setAPIError}
        isEmailValid={isEmailValid}
        setIsEmailValid={setIsEmailValid}
        setOpenLoginDialog={setOpenLoginDialog}
        transit={transit}
        setTransit={setTransit}
        setOpenVerificationDialog={setOpenVerificationDialog}
        sendVerificationEmail={handleSendVerificationDialogEmail}
      />
      <VerificationDialog
        open={openVerificationDialog}
        onClose={handleCloseDialog}
        setOpenVerificationDialog={setOpenVerificationDialog}
        setOpenRegisterDialog={setOpenRegisterDialog}
        setOpenLoginDialog={setOpenLoginDialog}
        username={username}
        transit={transit}
        setTransit={setTransit}
        loading={loading}
        sendVerificationEmail={sendVerificationEmail}
        APIError={APIError}
        isResettingPassword={isResettingPassword}
        sendPasswordVerificationEmail={handlePasswordSendVerificationDialog}
        // verificationFailed={verificationFailed}
      />
      <ResestPasswordDialog
        open={openResetPasswordDialog}
        onClose={handleCloseDialog}
        handleResetPassword={handleResetPassword}
        resetPassword={resetPassword}
        setResetPassword={setResetPassword}
        retypeResetPassword={retypeResetPassword}
        setRetypeResetPassword={setRetypeResetPassword}
        isPasswordMatch={isPasswordMatch}
        setIsPasswordMatch={setIsPasswordMatch}
        loading={loading}
        setLoading={setLoading}
        APIError={APIError}
        setAPIError={setAPIError}
        isEmailValid={isEmailValid}
        setIsEmailValid={setIsEmailValid}
        setOpenRegisterDialog={setOpenRegisterDialog}
        transit={transit}
        setTransit={setTransit}
        sendVerificationEmail={handleSendVerificationDialogEmail}
        sendPasswordVerificationEmail={handlePasswordSendVerificationDialog}
        verificationError={verificationError}
        setVerificationError={setVerificationError}
        setOpenLoginDialog={setOpenLoginDialog}
      />
    </Box>
  );
};

export default Login;
