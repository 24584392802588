import React, { useState, useEffect } from "react";
import { fetchData } from "../../services/apiConfig";
import CustomSuggestionProgress from "../atoms/Progress/CustomSuggestionProgress";
import {
  StyledPaperMain,
  StyledPaperHover,
  StyledTypography,
  StyledBox,
  StyledTitle,
  StyledImage,
} from "./Styled";
import { Grid } from "@mui/material";
import BuySell from "../../assests/BuySell.png";
import Reports from "../../assests/Reports.png";
import StockScreening from "../../assests/StockScreening.png";

const valueQuestions = [
  {
    prompt: "NVDA strengths, risks, competitor comparison?",
    prompt2: "Is AAPL a buy based on valuation and earnings?",
    prompt3: "Largecap tech stocks with high eps revisions?",
    icon: BuySell,
  },
  {
    prompt: "Undervalued large-cap stocks with growth and low debt?",
    prompt2: "Asset allocation for long-term growth?",

    icon: Reports,
  },
  {
    prompt: "TSLA earnings impact on long-term outlook?",
    prompt2: "Key tips before buying your first stock?",
    prompt3: "Crypto risks and safer investment alternatives?",
    icon: StockScreening,
  },
];

const QuestionTile = ({
  question,
  question2,
  question3,
  image,
  setNewMessage,
}) => (
  <>
    <StyledPaperHover onClick={() => setNewMessage(question)}>
      <StyledTypography variant="h6">{question}</StyledTypography>
    </StyledPaperHover>
    <StyledPaperHover onClick={() => setNewMessage(question2)}>
      <StyledTypography variant="h6">{question2}</StyledTypography>
    </StyledPaperHover>
    {question3 ? (
      <StyledPaperHover onClick={() => setNewMessage(question3)}>
        <StyledTypography variant="h6">{question3}</StyledTypography>
      </StyledPaperHover>
    ) : (
      <></>
    )}
  </>
);

const SuggestionTile = ({ setNewMessage, isMobile }) => {
  return (
    <StyledBox>
      <StyledTitle variant="h6">POPULAR QUESTIONS</StyledTitle>
      <Grid container spacing={2}>
        {valueQuestions.map((item, index) => (
          <Grid
            item
            xs={12} // Full width on small screens
            sm={4} // Full width on small screens
            md={4} // 50% width on medium screens
            lg={4} // 50% width on large screens
            key={index}
          >
            <QuestionTile
              question={item.prompt}
              question2={item.prompt2}
              question3={item.prompt3}
              image={item.icon}
              setNewMessage={setNewMessage}
            />
          </Grid>
        ))}
      </Grid>
    </StyledBox>
  );
};

export default SuggestionTile;
