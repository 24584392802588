import React, { useState } from "react";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import CustomButton from "../../atoms/Buttons/CustomButtons";
import CustomThreeDotLoader from "../../atoms/Progress/CustomThreeDotLoader";
import {
  StyledDialogRegister,
  StyledDialogTitleRegisterI,
  StyledDialogContent,
  StyledDialogActions,
  StyledIconButton,
  DialogSectionI,
} from "./LoginDialogStyled";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Google as GoogleIcon,
  Facebook as FacebookIcon,
} from "@mui/icons-material";
import { apiConfig } from "../../../services/apiConfig";

const RegisterDialog = ({
  open,
  onClose,
  onRegister,
  username,
  setUsername,
  newPassword,
  setNewPassword,
  retypePassword,
  setRetypePassword,
  isPasswordMatch,
  setIsPasswordMatch,
  loading,
  setLoading,
  APIError,
  setAPIError,
  isEmailValid,
  setIsEmailValid,
  setOpenLoginDialog,
  transit,
  setTransit,
  setOpenVerificationDialog,
  sendVerificationEmail,
}) => {
  const [isChecked, setIsChecked] = useState(false); // State for checkbox
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");

  const handleAgreementChange = (e) => {
    if (error === false) {
      setIsChecked(e.target.checked);
    }
  };

  const handleEmailChange = (value) => {
    setLoading(false);
    setUsername(value);
    setAPIError(false);
    setIsEmailValid(true);
    setIsPasswordMatch(null);
  };

  const handlePasswordChange = (value) => {
    setLoading(false);
    setNewPassword(value);
    setAPIError(false);
    setIsPasswordMatch(null);

    // Regular expressions for password validation
    const minLength = /^(?=.{8,})/; // Minimum length of 8
    const hasUpperCase = /^(?=.*[A-Z])/;
    const hasLowerCase = /^(?=.*[a-z])/;
    const hasNumber = /^(?=.*[0-9])/;
    const hasSpecialChar = /^(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/;

    if (!minLength.test(value)) {
      setError(true);
      setHelperText("Password must be at least 8 characters long.");
    } else if (!hasUpperCase.test(value)) {
      setError(true);
      setHelperText("Password must contain at least one uppercase letter.");
    } else if (!hasLowerCase.test(value)) {
      setError(true);
      setHelperText("Password must contain at least one lowercase letter.");
    } else if (!hasNumber.test(value)) {
      setError(true);
      setHelperText("Password must contain at least one number.");
    } else if (!hasSpecialChar.test(value)) {
      setError(true);
      setHelperText("Password must contain at least one special character.");
    } else {
      setError(false);
      setHelperText("");
    }
  };

  const handleReTypePasswordChange = (value) => {
    setLoading(false);
    setRetypePassword(value);
    setAPIError(false);
    setIsPasswordMatch(null);
  };

  const handleLoginClick = async () => {
    setTransit(true);
    await new Promise((resolve) => setTimeout(resolve, 200));
    onClose();
    setOpenLoginDialog(true);
    setTransit(false);
    setNewPassword("");
    setRetypePassword("");
    setAPIError(false);
    setError(false);
    setHelperText("");
    setIsEmailValid(true);
    setIsPasswordMatch(null);
  };

  const handleGoogleLogin = async () => {
    if (!apiConfig?.endpoints?.googleRedirect) {
      console.error("Google redirect URL is missing in apiConfig.");
      return;
    }
    window.location.href = apiConfig.endpoints.googleRedirect;
  };

  const handleFacebookLogin = async () => {
    if (!apiConfig?.endpoints?.metaRedirect) {
      console.error("Meta redirect URL is missing in apiConfig.");
      return;
    }
    window.location.href = apiConfig.endpoints.metaRedirect;
  };
  return (
    <StyledDialogRegister open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <StyledIconButton edge="end" color="inherit" onClick={onClose}>
        <CloseIcon />
      </StyledIconButton>
      <DialogSectionI moved={transit}>
        <StyledDialogTitleRegisterI>
          <Typography variant="h6" align="center">
            Create your account
          </Typography>
          <Typography variant="body2" align="center" color="textSecondary">
            We recommend using your personal email and strong password.
          </Typography>
        </StyledDialogTitleRegisterI>
        <StyledDialogContent>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <Button
              variant="outlined"
              startIcon={<GoogleIcon />}
              sx={{
                textTransform: "none",
                backgroundColor: "#DD4B39",
                color: "#fff",
                borderColor: "#DD4B39",
                padding: "10px",
                "&:hover": {
                  backgroundColor: "#C53727", // Darker shade for hover effect
                  borderColor: "#C53727",
                },
                "&:active": {
                  backgroundColor: "#B0301F", // Even darker shade for click effect
                  borderColor: "#B0301F",
                },
              }}
              onClick={handleGoogleLogin}
            >
              Sign Up with Google
            </Button>

            <Button
              variant="outlined"
              startIcon={<FacebookIcon />}
              sx={{
                textTransform: "none",
                backgroundColor: "#3b5998",
                borderColor: "#3b5998",
                padding: "10px",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#2d4373", // Darker shade for hover effect
                  borderColor: "#2d4373",
                },
                "&:active": {
                  backgroundColor: "#233862", // Even darker shade for click effect
                  borderColor: "#233862",
                },
              }}
              onClick={handleFacebookLogin}
            >
              Sign Up with Facebook
            </Button>
          </div>

          {/* OR Divider */}
          <div
            style={{ display: "flex", alignItems: "center", margin: "20px 0" }}
          >
            <Divider sx={{ flexGrow: 1 }} />
            <span style={{ margin: "0 10px", color: "#666", fontSize: "14px" }}>
              OR
            </span>
            <Divider sx={{ flexGrow: 1 }} />
          </div>
          <TextField
            label="Enter Email"
            variant="outlined"
            fullWidth
            margin="normal"
            color="success"
            sx={{ backgroundColor: "white" }}
            value={username}
            onChange={(e) => handleEmailChange(e.target.value)}
          />
          <div>
            <TextField
              label="Enter Password"
              variant="outlined"
              fullWidth
              margin="normal"
              type="password"
              color="success"
              sx={{ backgroundColor: "white" }}
              value={newPassword}
              onChange={(e) => handlePasswordChange(e.target.value)}
              error={error}
              helperText={helperText}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip
                      title={
                        <Box>
                          <ul>
                            <li>At least 8 characters long</li>
                            <li>
                              Contains at least one uppercase letter (A-Z)
                            </li>
                            <li>
                              Contains at least one lowercase letter (a-z)
                            </li>
                            <li>Includes at least one number (0-9)</li>
                            <li>
                              Contains at least one special character (e.g., !,
                              @, #, $, %, ^, &, *)
                            </li>
                          </ul>
                        </Box>
                      }
                      arrow
                      placement="right"
                    >
                      <IconButton>
                        <HelpOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <TextField
            label="Re-enter password"
            variant="outlined"
            fullWidth
            margin="normal"
            type="password"
            color="success"
            sx={{ backgroundColor: "white" }}
            value={retypePassword}
            onChange={(e) => handleReTypePasswordChange(e.target.value)}
          />
          <div>
            {APIError && (
              <Alert severity="error" sx={{ marginTop: "10px" }}>
                <strong>Error:</strong> {APIError}
              </Alert>
            )}
            {isEmailValid === false && (
              <Alert severity="warning" sx={{ marginTop: "10px" }}>
                <strong>Error:</strong> Please provide a genuine email address.
              </Alert>
            )}
            {isPasswordMatch === false && (
              <Alert severity="error" sx={{ marginTop: "10px" }}>
                <strong>Error:</strong> Passwords do not match.
              </Alert>
            )}
          </div>
          {/* Checkbox for terms and conditions */}

          <FormControlLabel
            control={
              <Checkbox
                color="success"
                checked={isChecked}
                onChange={handleAgreementChange}
              />
            }
            label={
              <>
                I agree to the
                <Link
                  target="_blank"
                  to="/termsandconditions"
                  style={{
                    textDecoration: "underline",
                    color: "#369902",
                    marginLeft: "5px",
                  }}
                >
                  Terms & Conditions
                </Link>{" "}
                and{" "}
                <Link
                  target="_blank"
                  to="/privacypolicy"
                  style={{
                    textDecoration: "underline",
                    color: "#369902",
                    marginLeft: "5px",
                  }}
                >
                  Privacy Policy
                </Link>
              </>
            }
            sx={{ marginTop: "10px", textAlign: "left", fontSize: "0.9rem" }}
          />
        </StyledDialogContent>
        <StyledDialogActions>
          {loading ? (
            <CustomButton color="log" variant="contained" disabled>
              <CustomThreeDotLoader />
            </CustomButton>
          ) : (
            <CustomButton
              color="log"
              variant="contained"
              onClick={onRegister}
              disabled={!isChecked} // Disable if checkbox is not selected
            >
              Continue
            </CustomButton>
          )}
          <Typography variant="body2" style={{ marginTop: "20px" }}>
            Already a user?{" "}
            <Typography
              variant="body2"
              component="span"
              onClick={handleLoginClick}
              style={{
                color: "#369902",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              Login
            </Typography>
          </Typography>
        </StyledDialogActions>
      </DialogSectionI>
    </StyledDialogRegister>
  );
};

export default RegisterDialog;
