import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Drawer as MuiDrawer } from "@mui/material"; // No need for alias 'MuiDrawer'
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuIcon from "@mui/icons-material/Menu";
import AddIcon from "@mui/icons-material/Add";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import CustomListItem from "../../atoms/List/CustomListItem";
import { fetchData } from "../../../services/apiConfig";
import CustomHistoryProgress from "../../atoms/Progress/CustomHistoryProgress";
import { ReactComponent as ZacksInsightLogo } from "../../../assests/ZacksInsightLogo.svg";
import { ReactComponent as NewChat } from "../../../assests/newChat.svg";
import { Drawer, DrawerHeader } from "./DrawerComponentStyled";
import DisclaimerDialog from "../DisclaimerDialog/DisclaimerDialog";

export default function DrawerComponent({
  open,
  onOpen,
  onClose,
  newMessage,
  setNewMessage,
  setNewChatClicked,
  newChatClicked,
}) {
  const [loading, setLoading] = useState(false);
  const [historyquestions, setHistoryquestion] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md")); // Check if screen size is small
  const [isDisclaimerDialogOpen, setIsDisclaimerDialogOpen] = useState(false); // State for dialog

  useEffect(() => {
    setLoading(true);
    try {
      const email = localStorage.getItem("email");
      if (email) {
        fetchData("getHistory", "POST", {
          email: email,
          k: 5,
        }).then((response) => {
          setLoading(false);
          if (response && response.length > 0) {
            setHistoryquestion(response);
          }
          // Open disclaimer dialog if response is empty
          if (response && response.length === 0) {
            setIsDisclaimerDialogOpen(true);
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
    // console.log(open);
  }, []);

  return (
    <Box>
      {isMobile ? (
        <MuiDrawer
          variant="temporary"
          open={!open}
          PaperProps={{
            sx: {
              width: 300,
              backgroundColor: "#124734",
              border: "none",
              minWidth: "68px !important",
              color: "#fff",
            },
          }}
        >
          <DrawerHeader>
            <IconButton
              color="inherit"
              aria-label="close drawer"
              onClick={open ? onClose : onOpen}
              edge="start"
              sx={{ marginLeft: 1.5 }}
            >
              <MenuIcon />
            </IconButton>
          </DrawerHeader>
          <div
            onClick={() => setNewChatClicked(!newChatClicked)}
            style={{
              backgroundColor: "#369902",
              display: "flex",
              alignItems: "center",
              fontSize: "14px",
              fontWeight: "500",
              height: "66px",
              opacity: !open ? 1 : 0,
              cursor: "pointer",
            }}
          >
            <NewChat
              style={{ width: "30px", height: "33px", marginLeft: "30px" }}
            />
            <Typography style={{ marginLeft: "20px", fontSize: "14px" }}>
              {" "}
              New Chat
            </Typography>
          </div>
          <Typography
            style={{
              marginLeft: "30px",
              fontSize: "14px",
              fontWeight: "normal",
              opacity: !open ? 1 : 0,
              marginTop: "10vh",
              fontFamily: "Inter, sans-serif",
              letterSpacing: "2.4px",
            }}
          >
            CHAT HISTORY
          </Typography>
          <List>
            {loading === false &&
            historyquestions &&
            historyquestions.length > 0 ? (
              historyquestions.map((prompts) => (
                <ListItem
                  key={prompts.promt_id}
                  disablePadding
                  sx={{ display: "block" }}
                  onClick={() => {
                    setNewMessage(prompts.prompt);
                  }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 40,
                      padding: "0px 20px",
                      width: "95%",
                      marginLeft: "10px",
                      border: "1px solid transparent",
                      borderRadius: "25px",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        justifyContent: "center",
                        marginRight: "10px",
                        borderRadius: "20px",
                      }}
                    >
                      <ChatBubbleOutlineIcon
                        style={{
                          opacity: !open ? 1 : 0,
                          width: "16px",
                          height: "16px",
                          color: "#ffffff",
                        }}
                      />
                    </ListItemIcon>
                    <CustomListItem
                      primary={prompts.prompt}
                      open={!open}
                      sx={{ opacity: !open ? 1 : 0, fontSize: "2px" }}
                      // setNewMessage={setNewMessage}
                    />
                  </ListItemButton>
                </ListItem>
              ))
            ) : loading === false &&
              historyquestions &&
              historyquestions.length === 0 ? (
              <></>
            ) : (
              [1, 2, 3, 4, 5].map((index) => (
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    sx={{
                      minHeight: 40,
                      padding: "0px 20px",
                      width: "95%",
                      marginLeft: "10px",
                      border: "1px solid transparent",
                      borderRadius: "25px",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        justifyContent: "center",
                        marginRight: "10px",
                        borderRadius: "20px",
                      }}
                    >
                      <ChatBubbleOutlineIcon
                        style={{
                          opacity: open ? 1 : 0,
                          width: "16px",
                          height: "16px",
                        }}
                      />
                    </ListItemIcon>
                    <CustomHistoryProgress />
                  </ListItemButton>
                </ListItem>
              ))
            )}
          </List>
        </MuiDrawer>
      ) : (
        <Drawer
          variant="permanent"
          open={open}
          PaperProps={{
            sx: {
              backgroundColor: "#124734",
              border: "none",
              minWidth: "68px !important",
              color: "#fff",
            },
          }}
        >
          <DrawerHeader
            sx={{
              justifyContent: "space-evenly",
              borderBottom: "1px solid black",
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={open ? onClose : onOpen}
              edge="start"
              sx={{ padding: "0px" }}
            >
              <MenuIcon />
            </IconButton>
            <ZacksInsightLogo
              style={{
                width: "169px",
                height: "17px",
                display: open ? "flex" : "none",
              }}
            />
          </DrawerHeader>
          <div
            onClick={() => setNewChatClicked(!newChatClicked)}
            style={{
              backgroundColor: "#369902",
              display: "flex",
              alignItems: "center",
              fontSize: "14px",
              fontWeight: "500",
              height: "66px",
              opacity: open ? 1 : 0,
              cursor: "pointer",
            }}
          >
            <NewChat
              style={{ width: "30px", height: "33px", marginLeft: "30px" }}
            />
            <Typography style={{ marginLeft: "20px", fontSize: "14px" }}>
              {" "}
              New Chat
            </Typography>
          </div>
          <Typography
            style={{
              marginLeft: "30px",
              fontSize: "14px",
              fontWeight: "normal",
              opacity: open ? 1 : 0,
              marginTop: "10vh",
              fontFamily: "Inter, sans-serif",
              letterSpacing: "2.4px",
            }}
          >
            CHAT HISTORY
          </Typography>
          <List>
            {loading === false &&
            historyquestions &&
            historyquestions.length > 0 ? (
              historyquestions.map((prompts) => (
                <ListItem
                  key={prompts.promt_id}
                  disablePadding
                  sx={{ display: "block" }}
                  onClick={() => {
                    setNewMessage(prompts.prompt);
                  }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 40,
                      padding: "0px 20px",
                      width: "95%",
                      marginLeft: "10px",
                      border: "1px solid transparent",
                      borderRadius: "25px",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        justifyContent: "center",
                        marginRight: "10px",
                        borderRadius: "20px",
                      }}
                    >
                      <ChatBubbleOutlineIcon
                        style={{
                          opacity: open ? 1 : 0,
                          width: "16px",
                          height: "16px",
                          color: "#ffffff",
                        }}
                      />
                    </ListItemIcon>
                    <CustomListItem
                      primary={prompts.prompt}
                      open={open}
                      sx={{ opacity: open ? 1 : 0, fontSize: "2px" }}
                      // setNewMessage={setNewMessage}
                    />
                  </ListItemButton>
                </ListItem>
              ))
            ) : loading === false &&
              historyquestions &&
              historyquestions.length === 0 ? (
              <></>
            ) : (
              [1, 2, 3, 4, 5].map((index) => (
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    sx={{
                      minHeight: 40,
                      padding: "0px 20px",
                      width: "95%",
                      marginLeft: "10px",
                      border: "1px solid transparent",
                      borderRadius: "25px",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        justifyContent: "center",
                        marginRight: "10px",
                        borderRadius: "20px",
                      }}
                    >
                      <ChatBubbleOutlineIcon
                        style={{
                          opacity: open ? 1 : 0,
                          width: "16px",
                          height: "16px",
                        }}
                      />
                    </ListItemIcon>
                    <CustomHistoryProgress />
                  </ListItemButton>
                </ListItem>
              ))
            )}
          </List>
        </Drawer>
      )}

      <DisclaimerDialog
        open={isDisclaimerDialogOpen}
        onClose={() => setIsDisclaimerDialogOpen(false)}
      />
    </Box>
  );
}
