import React from "react";
import { Container, Typography, Box, Grid } from "@mui/material";
import zackNewLogo from "../../assests/zackNewLogo.png";

const TnC = () => {
  return (
    <Container maxWidth="md">
      {/* Logo Section */}
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mt: 4 }}
        style={{ cursor: "pointer" }}
        onClick={() => window.location.replace("/")}
      >
        <Grid item>
          <Box
            component="img"
            src={zackNewLogo} // Replace with your logo path
            alt="Logo"
            sx={{ width: 50, height: 38 }}
          />
        </Grid>
        <Grid item>
          <Typography variant="h5" component="div">
            ZACKS
          </Typography>
        </Grid>
      </Grid>

      {/* Terms and Conditions Content */}
      <Box mt={4} mb={4}>
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          style={{ display: "flex", justifyContent: "center" }}
        >
          Terms and Conditions
        </Typography>
        <Typography variant="body1" paragraph>
          Zacks Intelligence LLC <strong> (“Zacks Intelligence”)</strong>{" "}
          welcomes you <strong>(“User”)</strong> to this website{" "}
          <strong>(the “Site”)</strong> . These legal notices and terms and
          conditions of use are a contract between User and Zacks Intelligence
          (the “Agreement”) and govern User’s visit to and use of the Site.
          Zacks Intelligence is an investment adviser registered with the United
          States Securities and Exchange Commission. Through use of artificial
          intelligence and language learning models, the Site generates
          investment recommendations, investment guidance, and financial/market
          related information based on User queries{" "}
          <strong>(the “Service”)</strong>. By accessing or using the Service,
          User accepts the terms of this Agreement <strong>(“Terms”)</strong>{" "}
          and User represents and warrants that it has the authority and
          capacity to enter into this Agreement. User may not access the Site or
          utilize the Service if it is not at least 18 years old. If User does
          not agree with all of the provisions of this Agreement, User must not
          access the Site or use the Service.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          1. ZACKS INTELLIGENCE CONTENT
        </Typography>
        <Typography variant="body1" paragraph>
          The materials, information and content made available or displayed on
          the Site or sent to User through the Service, and any derivative works
          thereof, whether made by Zacks Intelligence or User, (collectively,
          “Content”) are proprietary to Zacks Intelligence or its licensors.
          Subject to these Terms, Zacks Intelligence hereby grants User a
          limited, non-exclusive, non-transferable license to view, use,
          download and print the Content solely for User’s personal,
          informational, non-commercial and internal review and solely in
          accordance with this Agreement. User may not: (i) use the Content or
          any part thereof to develop products or technologies similar to the
          products of Zacks Intelligence; (ii) reproduce, republish, modify or
          alter the Content; (iii) distribute or sell, rent, lease, license or
          otherwise make the Content available to others; or (iv) otherwise
          remove any text, copyright or other proprietary notices contained in
          the Content. Any copy of the Content or portion thereof must include
          all copyright notices, information, and restrictions contained in, or
          attached to, any of the Content, and User must abide by the foregoing
          notices and restrictions. To request certain Content, User may be
          required to provide us with its contact information. User agrees to
          only provide Zacks Intelligence with true, accurate, current and
          complete information. As between Zacks Intelligence and User, Zacks
          Intelligence retains all right, title and interest in and to the
          Content, and all related intellectual property rights. Zacks
          Intelligence reserves all rights not granted in this Agreement. Upon
          Zacks Intelligence’s written request or User’s termination of these
          Terms, User must promptly delete or destroy all documents and other
          tangible materials representing any Content and all copies thereof.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          2. OWNERSHIP
        </Typography>
        <Typography variant="body1" paragraph>
          User understands and acknowledges that the software, code, proprietary
          methods and systems used to provide the Site or Service (“Zacks’
          Technology”) are: (i) copyrighted by Zacks Intelligence and/or Zacks
          Intelligence’s licensors under United States copyright laws; (ii)
          subject to other intellectual property and proprietary rights and
          laws; and (iii) owned by Zacks Intelligence or Zacks Intelligence’s
          licensors. Zacks’ Technology may not be copied, modified, reproduced,
          republished, posted, transmitted, sold, offered for sale, or
          redistributed in any way without Zacks Intelligence’s prior written
          permission and the prior written permission of Zacks Intelligence’s
          applicable licensors. User must abide by all copyright notices,
          information, and restrictions contained in or attached to any of
          Zacks’ Technology. Nothing in the Agreement grants User any right to
          receive delivery of a copy of Zacks’ Technology or to obtain access to
          Zacks’ Technology except as generally and ordinarily permitted through
          the Site according to the Agreement. Furthermore, nothing in the
          Agreement will be deemed to grant,by implication, estoppel or
          otherwise, a license to Zacks’ Technology. Certain of the names,
          logos, and other materials displayed on the Site or in the Service
          constitute trademarks, tradenames, service marks or logos (“Marks”) of
          Zacks Intelligence or other entities. User is not authorized to use
          any such Marks. Ownership of all such Marks and the goodwill
          associated therewith remains with Zacks Intelligence or those other
          entities.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          3. GENERAL RULES OF USER CONDUCT
        </Typography>
        <Typography variant="body1" paragraph>
          User agrees not to, and represents and warrants that User will not,
          reproduce, duplicate, copy, sell, resell or exploit any portion of the
          Site or Service, use of the Site or Service or access to the Site or
          Service for any purposes other than for which the Site or Services are
          being provided to User, or do any of the following: (i) conduct or
          promote any illegal activities while using the Site or Services; (ii)
          violate the rights of any party, including any intellectual property
          rights; (iii) attempt to reverse engineer or jeopardize the correct
          functioning of the Site, or otherwise attempt to derive the source
          code of the software (including the tools, methods, processes, and
          infrastructure) that enables or underlies the Site; (iv) attempt to
          gain access to secured portions of the Site or Services to which User
          does not possess access rights; (v) use any high volume automatic,
          electronic or manual process to access, search or harvest information
          from the Site or Service; (vi) interfere in any way with the proper
          functioning of the Site or Services or interfere with or disrupt any
          servers or networks connected to the Site or Services, or disobey any
          requirements, procedures, policies or regulations of networks
          connected to the Site or Services; (vii) use any robot, spider, other
          automatic device, or manual process to extract, “screen scrape,”
          monitor, “mine,” or copy any static or dynamic web page on the Site or
          the Content contained on any such web page for commercial use without
          Zacks Intelligence’s prior express written permission; (viii)
          impersonate any person or entity, or otherwise misrepresent User’s
          affiliation with a person or entity; or (ix) upload or transmit any
          form of virus, worm, Trojan horse, or other malicious code.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          4. MODIFICATIONS TO THE SITE OR SERVICES
        </Typography>
        <Typography variant="body1" paragraph>
          Zacks Intelligence reserves the right to modify or discontinue the
          Site or Services with or without notice to User. Zacks Intelligence
          will not be liable to User, or any third party should Zacks
          Intelligence exercise its right to modify or discontinue the Site
          and/or Services. If User objects to any such changes, User’s sole
          recourse will be to cease access to the Site or Services.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          5. MODIFICATIONS TO TERMS
        </Typography>
        <Typography variant="body1" paragraph>
          Zacks Intelligence may change the terms of the Agreement from time to
          time on a going-forward basis. Zacks Intelligence will notify User of
          any such material changes by posting notice of the changes on the
          Site, and/or, in Zacks Intelligence’s sole discretion, by email. Any
          such modifications become effective upon the earlier to occur of: (i)
          User’s acknowledgement of such modifications; or (ii) User’s continued
          access to and/or use of the Site or Service after Zacks Intelligence
          posts notice of such modifications. It is User’s sole responsibility
          to check the Site from time to time to view any such changes to the
          terms in the Terms of Use. If User does not agree to any changes, if
          and when such changes may be made to this Agreement, User must cease
          access to the Site and use of the Service.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          6. ACKNOWLEDGEMENTS
        </Typography>
        <Typography variant="body1" paragraph>
          User acknowledges and agrees that User (i) has received a copy of and
          carefully reviewed Zacks Intelligence’s Form ADV Part 2A, Form CRS,
          and Privacy Policy (the “Documents”); (ii) has had the opportunity to
          ask questions about the Documents and receive satisfactory answers;
          (iii) the Service is provided using data from research reports and
          general financial principles and is not tailored to the User’s
          personal financial needs/goals, individual circumstances, or risk
          tolerance; (iv) should verify any information received from the Site
          or Service before acting up on it and should consider whether the
          advice provided by the Service aligns with its financial situation and
          may wish to supplement Zacks Intelligence’s non- tailored
          recommendations with advice from financial, legal, and/or tax
          professionals; (v) understands the risks and limitations of the
          Service, including but not limited to the risks and limitations
          associated with non-tailored advice and the Zacks Intelligence’s use
          of artificial intelligence and large language models to provide the
          Service to User; and (vi) TO THE MAXIMUM EXTENT PERMITTED BY LAW,
          ZACKS INTELLIGENCE IS NOT LIABLE FOR ANY ACTION USER TAKES OR FAILS TO
          TAKE BASED UPON INFORMATION USER RECEIVED THROUGH THE SITE OR SERVICE.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          7. PRIVACY.
        </Typography>
        <Typography variant="body1" paragraph>
          User agrees to be bound by the Privacy Policy, which describes Zacks
          Intelligence’s collection, use and disclosure practices regarding any
          personal information that User provides to Zacks Intelligence. While
          there is no such thing as “perfect security” on the Internet, Zacks
          Intelligence will take reasonable steps to help ensure the safety of
          User’s personal information. However, User understands and agree that
          such steps do not guarantee that the Site and the Services are
          invulnerable to all security breaches or immune from viruses, security
          threats or other vulnerabilities.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          8. DISCLAIMER OF WARRANTIES
        </Typography>
        <Typography variant="body1" paragraph>
          USER EXPRESSLY AGREES THAT USER’S USE OF THE SITE AND SERVICE IS AT
          USER’S SOLE RISK. THE CONTENT AND SERVICE ARE PROVIDED BY ZACKS
          INTELLIGENCE ON AN “AS IS” AND “AS AVAILABLE” BASIS. TO THE MAXIMUM
          EXTENT PERMITTED BY LAW, ZACKS INTELLIGENCE EXPRESSLY DISCLAIMS ALL
          WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT
          LIMITED TO, ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
          PARTICULAR USE OR PURPOSE, NON- INFRINGEMENT, TITLE, OPERABILITY,
          CONDITION, QUIET ENJOYMENT, VALUE, ACCURACY OF DATA AND SYSTEM
          INTEGRATION. ZACKS INTELLIGENCE MAKES NO WARRANTY THAT THE CONTENT,
          SITE, AND/OR SERVICE WILL MEET USER’S REQUIREMENTS, OR THAT THE SITE
          AND/OR SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR FREE;
          NOR DOES ZACKS INTELLIGENCE MAKE ANY WARRANTY AS TO THE RESULTS THAT
          MAY BE OBTAINED FROM THE USE OF THE CONTENT, SITE, OR SERVICE, OR THAT
          DEFECTS IN THE SITE OR SERVICE WILL BE CORRECTED. USER UNDERSTANDS AND
          AGREES THAT ANY CONTENT OR INFORMATION DOWNLOADED OR OTHERWISE
          OBTAINED THROUGH THE USE OF THE SITE OR SERVICE IS DONE AT USER’S OWN
          DISCRETION AND RISK. NO CONTENT, ADVICE OR INFORMATION, WHETHER ORAL
          OR WRITTEN, OBTAINED BY USER FROM ZACKS INTELLIGENCE THROUGH THE SITE
          SERVICE, OR OTHERWISE WILL CREATE ANY WARRANTY, REPRESENTATION OR
          GUARANTEE NOT EXPRESSLY STATED IN THE AGREEMENT.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          9. LIMITATION OF LIABILITY
        </Typography>
        <Typography variant="body1" paragraph>
          USER ACKNOWLEDGES AND AGREES THAT ZACKS INTELLIGENCE IS ONLY WILLING
          TO PROVIDE ACCESS TO THE SITE AND TO PROVIDE THE SERVICE IF USER
          AGREES TO CERTAIN LIMITATIONS OF ZACKS INTELLIGENCE’S LIABILITY TO
          USER AND TO THIRD PARTIES. USER UNDERSTANDS THAT TO THE FULLEST EXTENT
          PERMITTED UNDER APPLICABLE LAW, IN NO EVENT WILL ZACKS INTELLIGENCE OR
          ITS OFFICERS, EMPLOYEES, DIRECTORS, PARENTS, SUBSIDIARIES, AFFILIATES,
          AGENTS OR LICENSORS BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
          CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO,
          DAMAGES FOR LOSS OF REVENUES, PROFITS, GOODWILL, USE, DATA, LOST
          OPPORTUNITIES, OR BUSINESS INTERRUPTIONS OR OTHER INTANGIBLE LOSSES
          (EVEN IF SUCH PARTIES WERE ADVISED OF, KNEW OF OR SHOULD HAVE KNOWN OF
          THE POSSIBILITY OF SUCH DAMAGES, AND NOTWITHSTANDING THE FAILURE OF
          ESSENTIAL PURPOSE OF ANY LIMITED REMEDY) ARISING OUT OF OR RELATED TO
          USERS USE OF OR ACCESS TO, OR THE INABILITY TO USE OR TO ACCESS, THE
          SITE OR THE SERVICE, REGARDLESS OF WHETHER SUCH DAMAGES ARE BASED ON
          CONTRACT, TORT (INCLUDING NEGLIGENCE AND STRICT LIABILITY), WARRANTY,
          STATUTE OR OTHERWISE. IN NO EVENT WILL ZACKS INTELLIGENCE BE LIABLE TO
          USER OR ANY THIRD PARTY IN CONNECTION WITH ANY ACT OR OMISSION OF ANY
          USER OF THE SITE. IF USER IS DISSATISFIED WITH ANY PORTION OF THIS
          SITE OR THE SERVICE, USERS SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE
          USE OF THE SITE AND THE SERVICE. ZACKS INTELLIGENCE’S TOTAL LIABILITY
          TO USER FOR ALL CLAIMS ARISING FROM OR RELATED TO THE SITE OR THE
          SERVICE IS LIMITED, IN AGGREGATE, TO ONE HUNDRED DOLLARS (U.S.
          $100.00). Some jurisdictions do not allow the exclusion of certain
          warranties or the limitation or exclusion of liability for incidental
          or consequential damages. Accordingly, some of the above limitations
          and disclaimers may not apply to User. To the extent that Zacks
          Intelligence may not, as a matter of applicable law, disclaim any
          implied warranty or limit liabilities, the scope and duration of such
          warranty and the extent of Zacks Intelligence liability will be the
          minimum permitted under such applicable law. Notwithstanding the
          foregoing, Zacks Intelligence does not disclaim any liability relating
          to the provision of services which cannot be disclaimed pursuant to
          the Investment Advisers Act of 1940, as amended.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          10. INDEMNIFICATION
        </Typography>
        <Typography variant="body1" paragraph>
          User agrees, at its own expense, to indemnify, defend and hold
          harmless Zacks Intelligence, its employees, representatives,
          suppliers, affiliated entities, assigns, agents, including third party
          agents (collectively “Indemnified Parties” each an “Indemnified
          Party”), against any claim, suit, action, or other proceeding brought
          against a Indemnified Party, to the extent that such claim, suit,
          action or other proceeding brought against such Indemnified Party is
          based on or arises in connection with the Service or use of this Site,
          including but not limited to (i) User’s (or someone using User’s
          computer) use of the Content, Site, or Service; (ii) User’s (or
          someone using User’s computer) violation of the Agreement, (iii)
          User’s (or someone using User’s computer) violation of any rights of
          any other person or entity; or (iv) any viruses, Trojan horses, worms,
          time bombs, cancelbots or other similar harmful or deleterious
          programming routines input by User (or someone using User’s computer)
          into the Site or Service. User agrees to pay any and all costs,
          damages, and expenses, including, but not limited to, reasonable
          attorneys’ fees and costs awarded against or otherwise incurred by or
          in connection with or arising from any such claim, suit, action or
          proceeding attributable to any such claim.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          11. THIRD-PARTY CONTENT AND OTHER WEBSITES
        </Typography>
        <Typography variant="body1" paragraph>
          Content from advertisers and other third parties may be made available
          to User through the Site and/or the Service. Because Zacks
          Intelligence does not control such content, User agrees that Zacks
          Intelligence is not responsible for any such content. Zacks
          Intelligence does not make any guarantees about the accuracy,
          currency, suitability, or quality of the information in such content,
          and Zacks Intelligence assumes no responsibility for unintended,
          objectionable, inaccurate, misleading, or unlawful content made
          available by other users, advertisers, and other third parties or
          violation of any third- party rights related to such Content. The Site
          and Service may contain links to websites not operated by Zacks
          Intelligence. Zacks Intelligence is not responsible for the content,
          products, materials, or practices (including privacy practices) of
          such websites. User understands that by using the Site and/or Service,
          User may be exposed to third-party websites that User finds offensive,
          indecent or otherwise objectionable. Zacks Intelligence makes no
          warranty, representation, endorsement, or guarantee regarding, and
          accepts no responsibility for, the quality, content, nature or
          reliability of third-party websites, products or services accessible
          by hyperlink or otherwise from the Site or the Service. Zacks
          Intelligence provides these links for User’s convenience only and
          Zacks Intelligence does not control such websites. The Site and
          Service may contain links to websites that are operated by an
          affiliate of Zacks Intelligence, but which operate under different
          terms of service. It is User’s responsibility to review the privacy
          policies and terms of service of any other website User visits. User
          agrees that in no event will Zacks Intelligence be liable to User in
          connection with any websites, content, products, materials, or
          practices of any third-party.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          12. DISPUTE RESOLUTION AND ARBITRATION AGREEMENT
        </Typography>
        <Typography variant="body1" paragraph>
          This Section 12 all be referred to herein as the “Arbitration
          Agreement.” Except for a claim by Zacks Intelligence of infringement
          or misappropriation of Zacks Intelligence’s patent, copyright,
          trademark, or trade secret, any and all disputes between User and
          Zacks Intelligence arising under or related in any way to these Terms
          or User’s use of the Site or the Service, or User’s receipt of any
          email, telephonic, text message or other communication from Zacks
          Intelligence or are representatives must be resolved through binding
          arbitration as described in this section. This Arbitration Agreement
          is intended to be interpreted broadly. USER AGREES THAT BY ENTERING
          INTO THIS ARBITRATION AGREEMENT, USER AND ZACKS INTELLIGENCE ARE EACH
          WAIVING THE RIGHT TO TRIAL BY JURY OR TO PARTICIPATE IN A CLASS
          ACTION. USER AND ZACKS INTELLIGENCE AGREE THAT EACH MAY BRING CLAIMS
          AGAINST THE OTHER ONLY IN ITS INDIVIDUAL CAPACITY, AND NOT AS A
          PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
          PROCEEDING. ANY ARBITRATION WILL TAKE PLACE ON AN INDIVIDUAL BASIS;
          CLASS ARBITRATIONS AND CLASS ACTIONS ARE NOT PERMITTED.
        </Typography>
        <Typography>
          a. Any and all controversies, disputes, demands, counts, claims, or
          causes of action (including the interpretation and scope of this
          Arbitration Agreement, and the arbitrability of the controversy,
          dispute, demand, count, claim, or cause of action) between User and
          Zacks Intelligence and Zacks Intelligence’s employees, agents,
          successors, or assigns, regarding or relating to the Services or these
          Terms, shall exclusively be settled through binding and confidential
          arbitration.
        </Typography>
        <Typography>
          b. Arbitration shall be subject to the Federal Arbitration Act and not
          any state arbitration law. The arbitration shall be conducted before
          one commercial arbitrator with substantial experience in resolving
          commercial contract disputes from JAMS. As modified by these Terms,
          and unless otherwise agreed upon by the parties in writing, the
          arbitration will be governed by JAMS’s rules for commercial
          arbitration and, if the arbitrator deems them applicable, the
          procedures for consumer-related disputes. If JAMS is unavailable to
          arbitrate, the parties will agree on an alternative arbitration forum.
        </Typography>
        <Typography>
          c. User is thus giving up its right to go to court to assert or defend
          its rights except for matters that may be taken to small claims court.
          User’s rights will be determined by a neutral arbitrator and not a
          judge or jury. User is entitled to a fair hearing, but the arbitration
          procedures are simpler and more limited than rules applicable in
          court. Arbitrator decisions are as enforceable as any court order and
          are subject to very limited review by a court.
        </Typography>
        <Typography>
          d. User and Zacks Intelligence must abide by the following rules: (1)
          any claims brought by User or Zacks Intelligence must be brought in
          the partys’ individual capacity, and not as a plaintiff or class
          member in any purported class or representative proceeding; (2) the
          arbitrator may not consolidate more than one person’s claims, may not
          otherwise preside over any form of a representative or class
          proceeding, and may not award class-wide relief; (3) in the event that
          User is able to demonstrate that the costs of arbitration will be
          prohibitive as compared to costs of litigation, Zacks intelligence
          will pay as much of Users filing and hearing fees in connection with
          the arbitration as the arbitrator deems necessary to prevent the
          arbitration from being cost-prohibitive as compared to the cost of
          litigation; (4) the arbitrator shall honor claims of privilege and
          privacy recognized at law; (5) the arbitrator’s award shall be final
          and may be enforced in any court of competent jurisdiction; (6) the
          arbitrator may award any individual relief or individual remedies that
          are permitted by applicable law; and (7) each side pays its own
          attorneys’ fees and expenses unless there is a statutory provision
          that requires the prevailing party to be paid its fees’ and litigation
          expenses, and then in such instance, the fees and costs awarded shall
          be determined by the applicable law.
        </Typography>
        <Typography>
          e. Notwithstanding the foregoing, either User or Zacks Intelligence
          may bring an individual action in small claims court provided such
          action remains in such court. Further, claims of infringement or
          misappropriation of the other party’s patent, copyright, trademark, or
          trade secret shall not be subject to this arbitration agreement. Such
          claims shall be exclusively brought in the state or federal courts
          located in Chicago, Illinois. Additionally, notwithstanding our
          agreement to arbitrate, either party may seek emergency equitable
          relief before the state or federal courts located in Chicago, Illinois
          in order to maintain the status quo pending arbitration, and the
          parties hereby agree to submit to the exclusive personal jurisdiction
          of the courts located within Chicago, Illinois for such purpose. A
          request for interim measures shall not be deemed a waiver of the right
          to arbitrate.
        </Typography>
        <Typography>
          f. With the exception of the second paragraph of this Arbitration
          Agreement and subparts (1) and (2) of subsection 12.d above
          (prohibiting arbitration on a class, representative or collective
          basis), if any part of this Arbitration Agreement is deemed to be
          invalid or unenforceable, then the balance of this Arbitration
          Agreement shall remain in effect and shall be construed in accordance
          with its terms as if the invalid, unenforceable, illegal or
          conflicting provision were not contained herein. If, however, the
          second paragraph of this Arbitration Agreement and/or subparts (1) or
          (2) of subsection 12.d above (prohibiting arbitration on a class,
          representative or collective basis) is found to be invalid or
          unenforceable with respect to a particular claim for relief, then that
          claim for relief and only that claim for relief shall be severed from
          the arbitration and brought exclusively in the state or federal courts
          located in Chicago, Illinois. All other claims shall be arbitrated.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          13. ELECTRONIC COMMUNICATIONS AND E-SIGN CONSENT
        </Typography>
        <Typography variant="body1" paragraph>
          Zacks Intelligence provides the Service by conducting business through
          the Internet, and therefore Zacks Intelligenceneeds User to consent to
          Zacks Intelligence giving User Communications (as defined below)
          electronically. User’s agreement to this Electronic Communications and
          E-Sign Consent confirms User’s ability and consent to receive all
          terms and conditions, agreements, notices, documents, disclosures, and
          other communications (“Communications”) electronically from Zacks
          Intelligence.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          14. GOVERNING LAW
        </Typography>
        <Typography variant="body1" paragraph>
          This Agreement shall be governed and construed in accordance with the
          laws of the United States and the State of Illinois, without giving
          effect to conflicts of law principles thereof. Any legal action, suit
          or proceeding arising out of or relating to the Terms of Use, or
          User’s use of the Site or Service not subject to arbitration under
          Section 12 must be instituted exclusively in the federal or state
          courts located Chicago, Illinois. User agrees to submit to the
          personal jurisdiction of the state and federal courts located in Cook
          County in the State of Illinois with respect to any legal proceedings
          that may arise in connection with the Service or from a dispute as to
          the interpretation or breach of the terms.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          15. ACCESSING ZACKS INTELLIGENCE OUTSIDE THE UNITED STATES
        </Typography>
        <Typography variant="body1" paragraph>
          Zacks Intelligence does not represent the Site, or the Service are
          appropriate or available for use in countries outside the United
          States. If User chooses to access the Service from outside the United
          States, User is responsible for compliance with foreign and local
          laws.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          16. ASSIGNMENT
        </Typography>
        <Typography variant="body1" paragraph>
          Neither Zacks nor User may assign or transfer any rights or
          obligations under this agreement without the other party’s consent.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          17. GENERAL TERMS
        </Typography>
        <Typography variant="body1" paragraph>
          Any delay or failure by us to exercise or enforce any right or
          provision of the Agreement will not constitute a waiver of such right
          or provision. No waiver by Zacks Intelligence will have effect unless
          such waiver is set forth in writing, signed by Zacks Intelligence; nor
          will any such waiver of any breach or default constitute a waiver of
          any subsequent breach or default. The Agreement constitutes the
          complete and exclusive agreement between Zacks Intelligence and User
          with respect to the subject matter hereof, and supersedes all prior
          oral or written understandings, communications or agreements. Subject
          to subsection 12.f above, if for any reason a court of competent
          jurisdiction finds any provision of the Agreement, or portion thereof,
          to be unenforceable, that provision of the Terms of Use will be
          enforced to the maximum extent permissible so as to effect the intent
          of the parties, and the remainder of the Agreement will continue in
          full force and effect. The paragraph headings in the Agreement, shown
          in boldface type, are included only to help make the Agreement easier
          to read and have no binding effect.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          18. SURVIVAL
        </Typography>
        <Typography variant="body1" paragraph>
          Sections 1, 2 and 8 through 18 as well as any other limitations on
          liability explicitly set forth herein and Zacks Intelligence’s
          proprietary rights in and to the Site, Content, Zacks’ Technology and
          the Service, will survive the expiration or termination of the
          Agreement for any reason.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          19. ACCOUNT DELETION POLICY
        </Typography>
        <Typography variant="body1" paragraph>
          Users may request the deletion of their account and personal data at
          any time by emailing{" "}
          <span style={{ textDecoration: "underline" }}>
            support@zacksintelligence.com
          </span>{" "}
          with the subject line Account Deletion Request. <br /> <br /> Zacks
          Intelligence will delete or anonymize User data, except for
          information Zacks Intelligence is legally required to retain for
          compliance, regulatory, or security purposes. This includes, but is
          not limited to, certain records, such as key conversational data,
          which must be retained to comply the Investment Advisers Act of 1940,
          as amended. Any retained data will be deleted or anonymized once Zacks
          Intelligence no longer needs to retain such data for compliance,
          regulatory, and security purposes, and any mandatory retention period
          expires.
        </Typography>
        {/* <Typography variant="h6" component="h2" gutterBottom>
          19. THE TELEPHONE CONSUMER PROTECTION ACT.
        </Typography>
        <Typography variant="body1" paragraph>
          Zacks Intelligence - Customer Support - <strong> 312-265-9157</strong>
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about these terms, feel free to contact us.
        </Typography> */}
      </Box>

      {/* Footer Section */}
      <Box
        sx={{
          mt: "auto",
          width: "100%",
          textAlign: "center",
          borderTop: "1px solid #ccc",
          pt: 2,
          pb: 2,
        }}
      >
        <Typography variant="body2">
          © {new Date().getFullYear()} Zacks intelligence. All rights reserved.
        </Typography>
        <Typography variant="body2">
          Zacks Intelligence LLC 10 S. Riverside Plaza, Suite 1600 Chicago, IL
          60606 (312)265-9157
        </Typography>
      </Box>
    </Container>
  );
};

export default TnC;
