import React, { Suspense } from "react";
import HeroSection from "../HeroSection/HeroSection";
// Lazy load sections
const Section2 = React.lazy(() => import("../Section2/Section2"));
const Section3 = React.lazy(() => import("../Section3/Section3"));
// const Section4 = React.lazy(() => import("../Section4/Section4"));
const Section5 = React.lazy(() => import("../Section5/Section5"));
const Section6 = React.lazy(() => import("../Section6/Section6"));
const Section7 = React.lazy(() => import("../Section7/Section7"));

export default function LandingPage({ handleLoginClick, handleRegisterClick }) {
  return (
    <React.Fragment>
      <HeroSection
        handleLoginClick={handleLoginClick}
        handleRegisterClick={handleRegisterClick}
      />

      <Suspense fallback={<div>Loading...</div>}>
        <Section2 />
      </Suspense>

      <Suspense fallback={<div>Loading...</div>}>
        <Section3
          handleLoginClick={handleLoginClick}
          handleRegisterClick={handleRegisterClick}
        />
      </Suspense>

      {/* You can also lazy load Section4 if you plan to use it */}
      {/* <Suspense fallback={<div>Loading...</div>}>
        <Section4 />
      </Suspense> */}

      <Suspense fallback={<div>Loading...</div>}>
        <Section5 />
      </Suspense>

      <Suspense fallback={<div>Loading...</div>}>
        <Section6 />
      </Suspense>

      <Suspense fallback={<div>Loading...</div>}>
        <Section7
          handleLoginClick={handleLoginClick}
          handleRegisterClick={handleRegisterClick}
        />
      </Suspense>
    </React.Fragment>
  );
}
