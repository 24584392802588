import React, { useContext, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { Box, CssBaseline } from "@mui/material";
import DrawerComponent from "../../components/templates/DrawerComponent/DrawerComponent";
import ChatInterface from "../../components/templates/ChatInterface/ChatInterface";
import UserMenu from "../../components/organisms/UserMenu";
import RightSideDrawer from "../../components/templates/RightSideDrawer/RightSideDrawer";
import ChatFooter from "../../components/organisms/ChatFooter";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export default function Home() {
  const { logout } = useContext(AuthContext);
  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isRightDrawer, setIsRightDrawer] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [newChatClicked, setNewChatClicked] = useState(false);
  const [tickerData, setTickerData] = useState([]);
  const [hothandOffDialogOpen, setHothandOffDialogOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const handleDrawerOpen = () => {
    setOpen(true);
    setDrawerOpen(false);
  };
  const handleDrawerClose = () => {
    setOpen(false);
    if (isRightDrawer && !isMobile) {
      setDrawerOpen(true);
    }
  };
  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleLogout = () => {
    logout();
    handleClose();
  };
  // Callback function to open the drawer when ChatInterface gives responseText1
  const handleTickerPanel = (newTickerData) => {
    // Combine the current tickerData with newData, then remove duplicates
    const updatedTickerData = [...tickerData, ...newTickerData].filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.Ticker === value.Ticker)
    );
    // Set the updated list
    if (!isMobile) {
      setTickerData(updatedTickerData);
      setTimeout(() => {
        setIsRightDrawer(true);
        setOpen(false);
        setDrawerOpen(true);
      }, 1000); // Delay of 500 milliseconds (0.5 seconds)
    }
    // }
  };

  return (
    <Box
      sx={{
        maxHeight: "100dvh",
        overflowY: "hidden !important",
        height: "100dvh",
      }}
    >
      <UserMenu
        anchorEl={anchorEl}
        handleMenu={handleMenu}
        handleClose={handleClose}
        handleLogout={handleLogout}
        onOpen={handleDrawerOpen}
        onClose={handleDrawerClose}
        hothandOffDialogOpen={hothandOffDialogOpen}
        setHothandOffDialogOpen={setHothandOffDialogOpen}
      />
      <Box
        sx={{
          display: "flex",
          width: "100vw",
          overflow: "hidden",
        }}
      >
        <CssBaseline />
        <DrawerComponent
          open={open}
          onOpen={handleDrawerOpen}
          onClose={handleDrawerClose}
          newMessage={newMessage}
          setNewMessage={setNewMessage}
          setNewChatClicked={setNewChatClicked}
          newChatClicked={newChatClicked}
        />

        <Box sx={{ flexGrow: 1 }}>
          <ChatInterface
            onResponsewithTicker={handleTickerPanel}
            open={open}
            newMessage={newMessage}
            setNewMessage={setNewMessage}
            newChatClicked={newChatClicked}
            tickerDrawerOpen={drawerOpen}
            hothandOffDialogOpen={hothandOffDialogOpen}
            setHothandOffDialogOpen={setHothandOffDialogOpen}
          />
        </Box>
        {isRightDrawer && !isMobile && (
          <RightSideDrawer
            open={drawerOpen}
            onClose={handleDrawerOpen}
            onOpen={handleDrawerClose}
            handleDrawerClose={handleDrawerClose}
            tickerData={tickerData}
          />
        )}
      </Box>
      <ChatFooter />
    </Box>
  );
}
