import React, { useState, useEffect, useRef } from "react";
import { List, ListItemAvatar, Avatar, Stack } from "@mui/material";
import CustomTextField from "../../molecules/CustomTextField/CustomTextField";
import CustomAvatar from "../../molecules/CustomAvatar/CustomAvatar";
import LoadingAvatar from "../../molecules/LoadingAvatar/LoadingAvatar";
import UserIcon from "../../../assests/userIcon.png";
import zackNewLogo from "../../../assests/zackNewLogo.png";
import SuggestionTile from "../../organisms/SuggestionTile";
import FeedbackTile from "../../organisms/FeedbackTile";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CustomLinearProgress from "../../atoms/Progress/CustomLinearProgess";
import {
  StyledContainer,
  StyledPaper,
  StyledMessageContainer,
  StyledList,
  StyledListItem,
  StyledListItemText,
  StyledNoMessagesText,
  StyledSubheading,
  StyledHomeScreen,
  StyledContainerTop,
} from "./ChatInterfaceStyled";
import { fetchStream, fectProfileData } from "../../../services/apiConfig";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import HotHandOff from "../../organisms/HotHandOff";
import UserInitialQuestions from "../../organisms/UserInitialQuestions";
import ChatFooter from "../../organisms/ChatFooter";

const ChatInterface = ({
  onResponsewithTicker,
  open,
  newMessage,
  setNewMessage,
  newChatClicked,
  tickerDrawerOpen,
  hothandOffDialogOpen,
  setHothandOffDialogOpen,
}) => {
  const [messages, setMessages] = useState([]);
  const [status, setStatus] = useState("Loading...");
  const [currentAbortController, setCurrentAbortController] = useState(null);
  const messagesEndRef = useRef(null); // Reference for scrolling
  const inputRef = useRef(null); // Reference for input field
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md")); // Check if screen size is small
  // const [hothandOffDialogOpen, setHothandOffDialogOpen] = useState(false);
  const email = localStorage.getItem("email");

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleServiceClick = async (tag) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "AdvisoryTagClicked",
      email: email,
    });
    try {
      const response = await fectProfileData("trackHotHandoff", "POST", {
        email: localStorage.getItem("email"),
        advisor_tag: tag,
        prompt_id: 123,
        was_clicked: true,
      });
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
    setHothandOffDialogOpen(true);
  };

  useEffect(() => {
    const questionTags = document.querySelectorAll(
      ".answer-message-content question"
    );
    const serviceTags = document.querySelectorAll(
      ".answer-message-content service, .answer-message-content advisor"
    );

    const handleClick = (event) => {
      setNewMessage(event.target.innerHTML);
    };

    const handleServiceTagClick = (event) => {
      console.log(event.target.innerHTML);
      handleServiceClick(event.target.innerHTML);
    };

    const trackServiceTagClick = (event) => {
      if (!event.target.dataset.processed) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "AdvisoryTagDisplayed",
          email: email,
        });
        event.target.dataset.processed = "true"; // Mark as processed
      }
    };

    // Attach event listeners
    questionTags.forEach((question) => {
      question.addEventListener("click", handleClick);
    });

    serviceTags.forEach((service) => {
      trackServiceTagClick({ target: service });
      service.addEventListener("click", handleServiceTagClick);
    });

    // Cleanup event listeners
    return () => {
      questionTags.forEach((question) => {
        question.removeEventListener("click", handleClick);
      });

      serviceTags.forEach((service) => {
        service.removeEventListener("click", handleServiceTagClick);
      });
    };
  }, [messages]);

  useEffect(() => {
    setMessages([]);
  }, [newChatClicked]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    // Focus the input on component mount
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [newMessage]);

  // let currentAbortController = null;
  const handleSendMessage = (lastmessage) => {
    // console.log("lastmessage", lastmessage);
    // Select all <question> and <service> elements inside .answer-message-content
    const questionAndServiceTags = document.querySelectorAll(
      ".answer-message-content question, .answer-message-content service"
    );

    // Loop through each element and hide it
    questionAndServiceTags.forEach((element) => {
      element.style.display = "none"; // Hide the element
    });

    if (lastmessage) {
      newMessage = lastmessage;
    }
    // console.log("newMessage", newMessage);
    if (newMessage.trim()) {
      // Abort the previous request if there's an ongoing one
      if (currentAbortController) {
        currentAbortController.abort();
      }

      // Create a new AbortController for the new request
      const newAbortController = new AbortController();
      setCurrentAbortController(newAbortController);

      const updatedMessages = messages.map((msg) => {
        return msg.isLoading === true
          ? {
              ...msg,
              text: "Request terminated. Please try again later.",
              isLoading: false, // Set isLoading to false
              isLoadingError: true,
            }
          : msg;
      });
      // Set updated messages
      setMessages([
        ...updatedMessages,
        { text: newMessage, isUser: true, isLoading: false },
      ]);
      // Clear the input
      setNewMessage(" ");

      // Display "loading..." message
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          text: "Loading........",
          isUser: false,
          isLoading: true,
          isStreaming: false,
        },
      ]);

      try {
        fetchStream(
          "getResponse",
          "POST",
          {
            prompt: newMessage,
            email: email,
            answer_format: "html",
          },
          setMessages,
          setStatus,
          newAbortController,
          onResponsewithTicker
        );
      } catch (error) {
        console.log(error);
      } finally {
      }
    }
  };

  const renderMessage = (messageText, promptId) => {
    const questionIndex = messageText.indexOf("<question>");

    if (questionIndex === -1) {
      return (
        <>
          <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            className="answer-message-content"
          >
            {messageText}
          </ReactMarkdown>
          <FeedbackTile messageText={messageText} promptId={promptId} />
        </>
      );
    }

    const beforeQuestion = messageText.slice(0, questionIndex); // Text before <question>
    const afterQuestion = messageText.slice(questionIndex); // Text after <question> (including <question> tag)

    return (
      <>
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          className="answer-message-content"
        >
          {beforeQuestion}
        </ReactMarkdown>
        <FeedbackTile messageText={messageText} promptId={promptId} />
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          className="answer-message-content"
        >
          {afterQuestion}
        </ReactMarkdown>
      </>
    );
  };

  return (
    <>
      <StyledContainerTop>
        <StyledPaper
          open={open}
          isMobile={isMobile}
          tickerDrawerOpen={tickerDrawerOpen}
        >
          <StyledMessageContainer>
            {messages.length > 0 ? (
              <StyledList>
                {messages.map((message, index) => (
                  <StyledListItem key={index} isQuestion={message.isUser}>
                    <ListItemAvatar>
                      {message.isUser ? (
                        <Avatar alt="User Icon" src={UserIcon} />
                      ) : message.isLoading && !message.isLoadingError ? (
                        <LoadingAvatar alt="Response Logo" src={zackNewLogo} />
                      ) : (
                        <CustomAvatar alt="Response Logo" src={zackNewLogo} />
                      )}
                    </ListItemAvatar>
                    <StyledListItemText
                      isQuestion={message.isUser}
                      primary={
                        <>
                          {message.isLoading ? (
                            <Stack
                              sx={{ width: "100%", color: "grey.500" }}
                              spacing={2}
                            >
                              <StyledListItemText
                                isLoading={true}
                                primary={<>{status}</>}
                              ></StyledListItemText>
                              {message.isLoadingError ? (
                                <></>
                              ) : (
                                <CustomLinearProgress />
                              )}
                            </Stack>
                          ) : message.isUser ? (
                            <span
                              className="question-message-content"
                              dangerouslySetInnerHTML={{ __html: message.text }}
                            />
                          ) : message.isUserProfileCapture ? (
                            <div>
                              <p>
                                Before proceeding further, could you provide
                                your investment profile details to provide
                                better responses?
                              </p>
                              <UserInitialQuestions
                                handleSendMessage={handleSendMessage}
                                setMessages={setMessages}
                                setNewMessage={setNewMessage}
                                messages={messages}
                                email={email}
                              />
                            </div>
                          ) : message.isFeedback ? (
                            // Use the renderMessage function to insert the FeedbackTile before <question> tag
                            <div>
                              {renderMessage(
                                message.text.replace(/\n{2,}/g, "\n"),
                                message.promptId
                              )}
                            </div>
                          ) : (
                            <div>
                              <ReactMarkdown
                                rehypePlugins={[rehypeRaw]}
                                className="answer-message-content"
                              >
                                {message.text.replace(/\n{2,}/g, "\n")}
                              </ReactMarkdown>
                            </div>
                          )}
                        </>
                      }
                    />
                  </StyledListItem>
                ))}
                <div ref={messagesEndRef} />
              </StyledList>
            ) : isMobile ? (
              <>
                {" "}
                <List style={{ overflow: "hidden" }}>
                  <StyledNoMessagesText
                    sx={{ fontSize: "5vw", textAlign: "center" }}
                  >
                    Hello,
                  </StyledNoMessagesText>
                  <StyledSubheading
                    sx={{
                      fontSize: "5vw",
                      fontWeight: 700,
                      textAlign: "center",
                    }}
                  >
                    How can I help you today?
                  </StyledSubheading>
                </List>
                <SuggestionTile setNewMessage={setNewMessage} />
              </>
            ) : (
              <StyledHomeScreen>
                {" "}
                <List style={{ overflow: "hidden", marginTop: "20px" }}>
                  <StyledNoMessagesText>Hello,</StyledNoMessagesText>
                  <StyledSubheading>How can I help you today?</StyledSubheading>
                </List>
                <SuggestionTile
                  setNewMessage={setNewMessage}
                  isMobile={isMobile}
                />
              </StyledHomeScreen>
            )}
          </StyledMessageContainer>

          <StyledContainer>
            <CustomTextField
              inputRef={inputRef}
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              handleSendMessage={handleSendMessage}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSendMessage();
                  e.preventDefault();
                }
              }}
            />
          </StyledContainer>
        </StyledPaper>
      </StyledContainerTop>
      {/* <ChatFooter /> */}
      <HotHandOff
        open={hothandOffDialogOpen}
        handleClose={() => setHothandOffDialogOpen(false)}
      />
    </>
  );
};

export default ChatInterface;
